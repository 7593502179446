export const TRACKING_ELEMENTS = [
  "ref",
  "utm_campaign",
  "utm_medium",
  "utm_source",
  "utm_term",
  "utm_content",
  "code",
];

export const MIXPANEL_SUPERPROPERTIES = ["ref", "code"];
