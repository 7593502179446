module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Customerly","short_name":"Customerly","description":"Create an astonishing customer service experience seamlessly","start_url":"/","background_color":"#4FBDFF","theme_color":"#ffffff","display":"standalone","icon":"src/media/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48eddc7d598d6a66cccf2812e2f21e44"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/media/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48eddc7d598d6a66cccf2812e2f21e44"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://staging.customerly.io"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api-blog.customerly.io/graphql","schema":{"timeout":3600000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/mnt/ramdisk/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/locales","i18nextOptions":{"ns":["translation"],"fallbackLng":"en","supportedLngs":["en","it"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TSDDQPX","includeInDevelopment":false,"routeChangeEventName":"page_view","defaultDataLayer":null,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManagerOptions":true,"iubendaOptions":{"consentOnContinuedBrowsing":false,"lang":"en","siteId":2099690,"floatingPreferencesButtonDisplay":false,"enableCMP":true,"googleAdditionalConsentMode":true,"isTCFConsentGlobal":false,"cookiePolicyId":45634728,"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"float-center","backgroundOverlay":true,"rejectButtonDisplay":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
