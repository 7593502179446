const { MIXPANEL_SUPERPROPERTIES } = require("./src/constants/tracking");
const { EVENT_PAGE_VIEW } = require("./src/constants/events");
const Cookies = require("universal-cookie").default;

exports.onRouteUpdate = ({ location }) => {
  const cookies = new Cookies(document.cookie);
  const rawCookies = {};

  MIXPANEL_SUPERPROPERTIES.forEach((tracker) => {
    const cookie = cookies.get(tracker);
    if (cookie !== null && cookie !== undefined && cookie !== "") {
      rawCookies[tracker] = cookie;
    }
  });

  if (window) {
    if (window.customerly) {
      window.customerly.update();
    }

    if (window.mixpanel) {
      window.mixpanel.register_once(rawCookies);
      window.mixpanel.track(EVENT_PAGE_VIEW, location);
    }
  }
};
