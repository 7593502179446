// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-live-chat-tsx": () => import("./../../../src/pages/add-live-chat.tsx" /* webpackChunkName: "component---src-pages-add-live-chat-tsx" */),
  "component---src-pages-agencies-tsx": () => import("./../../../src/pages/agencies.tsx" /* webpackChunkName: "component---src-pages-agencies-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-features-audience-segmentation-tsx": () => import("./../../../src/pages/features/audience-segmentation.tsx" /* webpackChunkName: "component---src-pages-features-audience-segmentation-tsx" */),
  "component---src-pages-features-conversation-inbox-tsx": () => import("./../../../src/pages/features/conversation-inbox.tsx" /* webpackChunkName: "component---src-pages-features-conversation-inbox-tsx" */),
  "component---src-pages-features-crm-tsx": () => import("./../../../src/pages/features/crm.tsx" /* webpackChunkName: "component---src-pages-features-crm-tsx" */),
  "component---src-pages-features-email-marketing-tsx": () => import("./../../../src/pages/features/email-marketing.tsx" /* webpackChunkName: "component---src-pages-features-email-marketing-tsx" */),
  "component---src-pages-features-email-template-builder-tsx": () => import("./../../../src/pages/features/email-template-builder.tsx" /* webpackChunkName: "component---src-pages-features-email-template-builder-tsx" */),
  "component---src-pages-features-help-center-tsx": () => import("./../../../src/pages/features/help-center.tsx" /* webpackChunkName: "component---src-pages-features-help-center-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-features-live-chat-api-tsx": () => import("./../../../src/pages/features/live-chat-api.tsx" /* webpackChunkName: "component---src-pages-features-live-chat-api-tsx" */),
  "component---src-pages-features-live-chat-customization-tsx": () => import("./../../../src/pages/features/live-chat-customization.tsx" /* webpackChunkName: "component---src-pages-features-live-chat-customization-tsx" */),
  "component---src-pages-features-live-chat-engagement-tsx": () => import("./../../../src/pages/features/live-chat-engagement.tsx" /* webpackChunkName: "component---src-pages-features-live-chat-engagement-tsx" */),
  "component---src-pages-features-live-chat-for-mobile-apps-tsx": () => import("./../../../src/pages/features/live-chat-for-mobile-apps.tsx" /* webpackChunkName: "component---src-pages-features-live-chat-for-mobile-apps-tsx" */),
  "component---src-pages-features-live-chat-for-website-tsx": () => import("./../../../src/pages/features/live-chat-for-website.tsx" /* webpackChunkName: "component---src-pages-features-live-chat-for-website-tsx" */),
  "component---src-pages-features-live-chat-plugin-for-shopify-tsx": () => import("./../../../src/pages/features/live-chat-plugin-for-shopify.tsx" /* webpackChunkName: "component---src-pages-features-live-chat-plugin-for-shopify-tsx" */),
  "component---src-pages-features-live-chat-plugin-for-wordpress-tsx": () => import("./../../../src/pages/features/live-chat-plugin-for-wordpress.tsx" /* webpackChunkName: "component---src-pages-features-live-chat-plugin-for-wordpress-tsx" */),
  "component---src-pages-features-live-chat-tsx": () => import("./../../../src/pages/features/live-chat.tsx" /* webpackChunkName: "component---src-pages-features-live-chat-tsx" */),
  "component---src-pages-features-marketing-funnel-tsx": () => import("./../../../src/pages/features/marketing-funnel.tsx" /* webpackChunkName: "component---src-pages-features-marketing-funnel-tsx" */),
  "component---src-pages-features-net-promoter-score-tsx": () => import("./../../../src/pages/features/net-promoter-score.tsx" /* webpackChunkName: "component---src-pages-features-net-promoter-score-tsx" */),
  "component---src-pages-features-smart-assignment-rules-tsx": () => import("./../../../src/pages/features/smart-assignment-rules.tsx" /* webpackChunkName: "component---src-pages-features-smart-assignment-rules-tsx" */),
  "component---src-pages-features-surveys-tsx": () => import("./../../../src/pages/features/surveys.tsx" /* webpackChunkName: "component---src-pages-features-surveys-tsx" */),
  "component---src-pages-features-team-reports-tsx": () => import("./../../../src/pages/features/team-reports.tsx" /* webpackChunkName: "component---src-pages-features-team-reports-tsx" */),
  "component---src-pages-features-video-live-chat-tsx": () => import("./../../../src/pages/features/video-live-chat.tsx" /* webpackChunkName: "component---src-pages-features-video-live-chat-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-ecommerce-tsx": () => import("./../../../src/pages/industries/ecommerce.tsx" /* webpackChunkName: "component---src-pages-industries-ecommerce-tsx" */),
  "component---src-pages-industries-software-as-a-service-tsx": () => import("./../../../src/pages/industries/software-as-a-service.tsx" /* webpackChunkName: "component---src-pages-industries-software-as-a-service-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-not-found-tsx": () => import("./../../../src/pages/not-found.tsx" /* webpackChunkName: "component---src-pages-not-found-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-solutions-customer-satisfaction-tsx": () => import("./../../../src/pages/solutions/customer-satisfaction.tsx" /* webpackChunkName: "component---src-pages-solutions-customer-satisfaction-tsx" */),
  "component---src-pages-solutions-customer-service-tsx": () => import("./../../../src/pages/solutions/customer-service.tsx" /* webpackChunkName: "component---src-pages-solutions-customer-service-tsx" */),
  "component---src-pages-solutions-marketing-automation-tsx": () => import("./../../../src/pages/solutions/marketing-automation.tsx" /* webpackChunkName: "component---src-pages-solutions-marketing-automation-tsx" */),
  "component---src-pages-startups-tsx": () => import("./../../../src/pages/startups.tsx" /* webpackChunkName: "component---src-pages-startups-tsx" */),
  "component---src-pages-success-stories-tsx": () => import("./../../../src/pages/success-stories.tsx" /* webpackChunkName: "component---src-pages-success-stories-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-try-customerly-best-intercom-alternative-tsx": () => import("./../../../src/pages/try-customerly/best-intercom-alternative.tsx" /* webpackChunkName: "component---src-pages-try-customerly-best-intercom-alternative-tsx" */),
  "component---src-pages-webinars-tsx": () => import("./../../../src/pages/webinars.tsx" /* webpackChunkName: "component---src-pages-webinars-tsx" */),
  "component---src-templates-alternative-tsx": () => import("./../../../src/templates/alternative.tsx" /* webpackChunkName: "component---src-templates-alternative-tsx" */),
  "component---src-templates-blog-author-index-tsx": () => import("./../../../src/templates/blog/author/index.tsx" /* webpackChunkName: "component---src-templates-blog-author-index-tsx" */),
  "component---src-templates-blog-category-index-tsx": () => import("./../../../src/templates/blog/category/index.tsx" /* webpackChunkName: "component---src-templates-blog-category-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blog/post/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-blog-tag-index-tsx": () => import("./../../../src/templates/blog/tag/index.tsx" /* webpackChunkName: "component---src-templates-blog-tag-index-tsx" */),
  "component---src-templates-success-story-index-tsx": () => import("./../../../src/templates/success-story/index.tsx" /* webpackChunkName: "component---src-templates-success-story-index-tsx" */),
  "component---src-templates-webinar-index-tsx": () => import("./../../../src/templates/webinar/index.tsx" /* webpackChunkName: "component---src-templates-webinar-index-tsx" */)
}

